import React from 'react'
import './Hero.css'
// import logo from ' assets/images/AgroBolt (1).png'
import logo from '../../assets/images/AgroBolt (1).png'

const Hero = () => {
	return (
		<>
			<section id="home">
				<div className="hero-container">
					<img src={logo} style={{width: "300px"}} alt="" />
					<h1>Transforming <span className="tags"> Agri Research through </span> AI</h1>
					<h4>powered by <span className="tags"> Boltzmann</span></h4>
				</div>
			</section>
		</>
	)
}

export default Hero