import React, { useEffect, useState } from 'react'
import "./BlogPosts.css"
import { db, doc } from '../../firebaseConfig'
import { documentId, getDoc } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import Htmlrendering from '../../utils/Htmlrendering/Htmlrendering'
import Scrollto from '../../components/Scrollto/Scrollto'
import { onSnapshot, collection } from '../../firebaseConfig'
import { NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'
import Imgrendering from '../../utils/Imgrendering/Imgrendering'
import Detailrendering from '../../utils/Detailrendering/Detailrendering'

const breadstyle = {
    position: "relative",
    zIndex: "1",
    padding: "235px 0px 125px",
    overflow: "hidden",
    background: "green"
}

const BlogPosts = () => {
    const [loader, setLoader] = useState(true)
    const [blogdata, setBlogdata] = useState({})
    const [dataArray, setDataArray] = useState([]);
    const navigate = useNavigate()
    const { id } = useParams()

    const getRelatedPosts = () => {
        const unsubscribe = onSnapshot(collection(db, 'agrebolt-posts'), (querySnapshot) => {
            const newDataArray = [];
            querySnapshot.forEach((doc) => {
                newDataArray.push({ id: doc.id, ...doc.data() });
            });
            console.log(newDataArray)
            setDataArray(newDataArray);
        });

        setTimeout(() => {
            setLoader(false)
        }, 2000);
    }

    const getBlog = async () => {
        try {
            setLoader(true)
            const docRef = doc(db, "agrebolt-posts", id);
            const docSnap = await getDoc(docRef);
            await getRelatedPosts()

            if (docSnap.exists()) {
                console.log('Document data:', docSnap.data());
                setBlogdata(docSnap.data())

            } else {
                console.log('No such document!');
                setLoader(false)
            }
        } catch (error) {
            console.error('Error getting document:', error);
        }
    }



    useEffect(() => {
        getBlog()
        getRelatedPosts()
        window.scrollTo(0, 0)

        return () => {
        }
    }, [id])

    return (
        <>
            <Helmet>
                {blogdata.title && <title>Agribolt | {blogdata.title} </title>}
                <meta name="description" content={blogdata.content} />
            </Helmet>
            {loader && <div className="loader"></div>}
            <Scrollto />
            {/* <section style={breadstyle}>
                <img className="breadcrumb_shap" src="img/breadcrumb/banner_bg.png" alt="" />
                <div className="container">
                    <div className="breadcrumb_content text-center">
                        <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">{id}</h1>
                        <p className="f_400 w_color f_size_16 l_height26">Why I say old chap that is spiffing off his nut arse
                            pear shaped plastered<br /> Jeffrey bodge barney some dodgy.!!</p>
                    </div>
                </div>
            </section> */}

            <section className="blog_area sec_pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12blog_sidebar_left">
                            <div className="blog_single mb_50">
                                <div className="blog_content">
                                    <div style={{ padding: '25px', backgroundColor: 'white' }}>
                                        {blogdata.title && <h5 className="f_p f_size_20 f_500 t_color mb-30 len_text">{blogdata.title}</h5>}
                                        <div class="entry_post_info">
                                            By: <NavLink to={`/blog/${blogdata.id}/blog-post`}>{blogdata.author}</NavLink>
                                            {blogdata.created_date && <NavLink to={`/blog/${blogdata.id}/blog-post`}>{blogdata.created_date.toDate().toString().slice(4, 15)}</NavLink>}
                                        </div>
                                        <Detailrendering
                                            content={blogdata.content}
                                            type="detailed"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="blog_post">
                                <div className="widget_title">
                                    <h3 className="f_p f_size_20 t_color3">Related Post</h3>
                                    <div className="border_bottom"></div>
                                </div>
                                <div className="row g-2">
                                    {
                                        dataArray.slice(0, 3).map((data) =>
                                            <>
                                                {
                                                    data.published == true &&
                                                    <div className="col-lg-4 col-sm-6" onClick={() => navigate(`/post/${data.id}`)}>
                                                        <div className="blog_post_item">
                                                            <div className="blog_content">
                                                                <div class="blog_img">
                                                                    <Imgrendering
                                                                        htmlString={data.content}
                                                                    />
                                                                </div>
                                                                {data.title
                                                                    &&
                                                                    <NavLink to={`/post/${data.id}`}>
                                                                        <h2 className="f_p f_size_16 f_500 t_color len_text">{data.title}</h2>
                                                                    </NavLink>
                                                                }

                                                                <div class="entry_post_info">
                                                                    By: <a to={`/post/${data.id}`}>{data.author}</a>
                                                                    <a to={`/post/${data.id}`}>{data.created_date.toDate().toString().slice(4, 15)}</a>
                                                                </div>
                                                                <div className='blog-description'>
                                                                    <Htmlrendering
                                                                        content={data.content}
                                                                        type="detailed"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogPosts