import React from 'react'
import { useEffect, useState } from 'react'
import place_holder from '../../img/place_holder.jpg'

const Imgrendering = ({ htmlString, classname }) => {
    const [imgUrl, setimgUrl] = useState('')
    useEffect(() => {
        const extractImageSource = (htmlString) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');
            const imgElement = doc.querySelector('img');
            if (imgElement) {
                const imageSource = imgElement.getAttribute('src');
                console.log('Image Source:', imageSource);
                setimgUrl(imageSource)
            } else {
                console.log('No image found in the HTML string.');
            }
        };
        extractImageSource(htmlString)
        return () => {
        }
    }, [htmlString])

    return imgUrl != ''
        ?
        <img src={imgUrl} className={classname} />
        :
        <img src={place_holder} alt='blog' className={classname} />
}

export default Imgrendering