import React from 'react'
import './Footer.css'
const Footer = () => {
	return (
		<>
			<footer className="footer style-3 ">
				<div className="container" >
					<div className="row">
						<div className="col-12">
							<div className="f-social-area">
								<ul className="f-contact-list">
									<li>
										<span>Follow Us:</span>
										<ul className="social-profile style-wb">
											{/* <li><a href="https://www.facebook.com/bayeslabs.co"><i className="social_facebook"></i></a></li>
											<li><a href="https://twitter.com/BayesLabs"><i className="social_twitter"></i></a></li> */}
											<li><a href="remove all the social media link ,
add only linkedin page  https://www.linkedin.com/company/agribolt/"><i
												className="social_linkedin"></i></a></li>
										</ul>
									</li>
								</ul>
							</div>
							<div className="copyright-area">
								<p className="copyright">© 2022 <a href="#">Agribolt</a> ALL RIGHTS RESERVED</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer