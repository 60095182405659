import React from 'react'
import './Contact.css'
const Contact = () => {
  return (
    <>
    <section className="h4-contact s-padding bg-color" id="Contact">
		<div className="container">
			<div className="row">
				<div className="col-lg-5">
					<div className="iconic-contact-info">
						<div className="contact-info-head">
							<h2>Contact Information</h2>
						</div>
						<ul className="f-contact-list">
							<li><span>Address:</span> B-305, Block II, KSSIDC Complex, Electronic City Phase I,
								Bengaluru 560100</li>
							<li><span>Call Us:</span> +91 9498866488</li>
							<li><span>Email Us:</span> contact@bayeslabs.co</li>
						</ul>
					</div>
				</div>
				<div className="col-lg-6 offset-lg-1">
				</div>
			</div>
		</div>
	</section>
    </>
  )
}

export default Contact