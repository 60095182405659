import React from 'react'
import './Header.css'
import logo2 from "../../assets/images/AgroBolt (1).png"
import { NavLink, useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


const Header = () => {


    const caseconverter = (text) => {
        const fintext = text.toUpperCase();
        return fintext
    }
    const navigate = useNavigate()

    const navigateto1 = () => {
        navigate('/#Vision')
    }

    const navigateto2 = () => {
        navigate('/#Why')
    }

    const navigateto3 = (e) => {
        navigate('/agribolt/blogs')
    }

    const navigateto4 = () => {
        navigate('/#Contact')
    }
    return (
        <>
            <header className="header">
                <nav className="navbar navbar-expand-lg fixed-top" id="main-nav">
                    <div className="container">
                        <HashLink className="navbar-brand" to="/#home">
                            <img className="white-logo" style={{ width: "110px", margin: 0 }} src={logo2} alt="" />
                            {/* <h5>Agrebolt</h5> */}
                        </HashLink>
                        <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#main-nav-collapse" aria-controls="main-nav-collapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu-toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>
                        <div className="collapse navbar-collapse order-3 order-lg-2" id="main-nav-collapse">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <HashLink to="/#home" className="nav-link" aria-haspopup="true" aria-expanded="false">
                                        {caseconverter('home')}
                                    </HashLink>
                                </li>
                                <li className="nav-item">

                                    <HashLink to="/#Vision">{caseconverter('vision')}</HashLink>
                                    {/* <a className="nav-link nav-link-scroll" onClick={navigateto1} href="#Vision">Vision</a> */}
                                </li>
                                <li className="nav-item">
                                    <HashLink to="/#Why">{caseconverter('why')}</HashLink>
                                    {/* <a className="nav-link nav-link-scroll" onClick={navigateto2} href=''>Why us</a> */}
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/blogs">{caseconverter('blog')}</NavLink>
                                </li>
                                <li className="nav-item">
                                    <HashLink to="/#Contact">{caseconverter('contact')}</HashLink>
                                    {/* <a className="nav-link nav-link-scroll" onClick={navigateto4} href="#Contact">contact</a> */}
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header