import React from 'react'
import './Why.css'
import bulb from '../../img/plant-growing-bulb-min.jpg'
import { useState, useRef, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


const Why = () => {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Crop Protectants</Popover.Header>
            <Popover.Body>
                The two secret weapons for crop protection are synthetic fertilizers and chemical
                pesticides. Chemical crop protection is still a crucial component of modern agriculture
                as it helps to conserve resources and concentrate on yield and quality. To ensure
                efficient & sustainable food production, there is a rising need for novel & enhanced
                crop protectants.
            </Popover.Body>
        </Popover>
    );

    const popover2 = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Herbicide Safeners</Popover.Header>
            <Popover.Body>
                They are a group of agrochemicals that have the ability to selectively shield crops from herbicide
                damage while maintaining herbicide potency. Herbicide phytotoxicity to crop plants is substantially
                reduced by safeners. Enhancing the metabolism of herbicides in crops, changing their absorption and
                transportation, competitively binding to herbicide target sites, and influencing the activity of
                target enzymes are just a few of the ways that herbicides can be used.
            </Popover.Body>
        </Popover>
    );

    const popover3 = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Bio-stimulants</Popover.Header>
            <Popover.Body>
                Bio-stimulants, whether exogenously applied or produced internally by genetically modified plants,
                can ramp up plant growth, productivity, and stress tolerance. We can increase crop stress tolerance
                and productivity by deciphering the molecular complexity of PAs signaling in plants. Plant stress
                biology is concentrating on phytohormone-like plant growth regulators since they have the
                potential to provide plants with amazing advantages including higher crop yield, enhanced growth
                characteristics, and stress tolerance.

            </Popover.Body>
        </Popover>
    );

    const popover4 = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Fertilizers</Popover.Header>
            <Popover.Body>
                The most crucial micronutrients for all living things are nitrogen, potassium, and phosphorus.
                Using Smart fertilizers, which contain solubilizing microbes like PSMs or fertilizers with a
                controlled release mechanism, can prevent problems like eutrophication. These are steps in the
                direction of environmentally sustainable methods of farming.
            </Popover.Body>
        </Popover>
    );

    const popover5 = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Soil conditioners</Popover.Header>
            <Popover.Body>
                Soil conditioners can be used to repair deficient soils or regenerate soils harmed by inadequate
                soil management. They have the ability to improve poor soils and to keep soils in good shape.
                They support cation exchange, water retention, soil structure, and soil nutrients.
                For ecological reasons, it is a good idea to test the soil and select a soil conditioner that
                is appropriate for it.
            </Popover.Body>
        </Popover>
    );

    const popover6 = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Crop improvement</Popover.Header>
            <Popover.Body>
                Due to the changing global environment, crops can be more resistant to biotic and abiotic challenges.
                have higher nutritional value, and yield more as a result of crop improvement.
                It is also required t o close the gap between supply and demand for plant-based goods.
                Systems biology, plant breeding, transgenics,
                and metabolomics-assisted approaches are some possible methods.

            </Popover.Body>
        </Popover>
    );

    useEffect(() => {
      return () => {
      }
    }, [])
    

    return (
        <>
            <section id="Why" className="why">
                <div className="container">
                    <div className="why-section-title">
                        <h2>We are the new definition of agro-chemical discovery</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-lg-12 mb-2 mt-2">
                                    <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                Crop Protectants
                                            </h5>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                <div className="col-lg-12 mb-2 mt-2">
                                    <OverlayTrigger trigger="hover" placement="bottom" overlay={popover2}>
                                        <div className="card-body">
                                            <h5 className="card-title">Herbicide Safeners </h5>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                <div className="col-lg-12 mb-2 mt-2">
                                    <OverlayTrigger trigger="hover" placement="bottom" overlay={popover3}>
                                        <div className="card-body">
                                            <h5 className="card-title">Bio-stimulants </h5>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 why-img" style={{ display: "flex" }}>
                            <img src={bulb}
                                style={{ width: "300px", display: "block", alignItems: "center", margin: "auto" }} alt="" />
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-lg-12 mb-2 mt-2 ">
                                    <OverlayTrigger trigger="hover" placement="bottom" overlay={popover4}>
                                        <div className="card-body">
                                            <h5 className="card-title">Fertilizers </h5>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                <div className="col-lg-12 mb-2 mt-2">
                                    <OverlayTrigger trigger="hover" placement="bottom" overlay={popover5}>
                                        <div className="card-body">
                                            <h5 className="card-title">Soil conditioners</h5>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                <div className="col-lg-12 mb-2 mt-2">
                                    <OverlayTrigger trigger="hover" placement="bottom" overlay={popover6}>
                                        <div className="card-body">
                                            <h5 className="card-title">Crop improvement</h5>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Why