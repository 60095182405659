import React from 'react'
import { useEffect, useState } from 'react';
import Hero from '../../components/Hero/Hero'
import Blogs from '../../components/blogs/Blogs'
import Contact from '../../components/contatinfo/Contact'
import Vision from '../../components/Vision/Vision'
import Why from '../../components/Why/Why'
import Technology from '../../components/Technology/Technology'
import Whyus from '../../components/whyUs/Whyus'
import Scrollto from '../../components/Scrollto/Scrollto';
import { collection, db, onSnapshot } from '../../firebaseConfig';
import { Helmet, HelmetProvider } from "react-helmet-async";

const Home = () => {
    const [dataArray, setDataArray] = useState([]);
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'agrebolt-posts'), (querySnapshot) => {
            const newDataArray = [];
            querySnapshot.forEach((doc) => {
                if(doc.data().published==true){
                newDataArray.push({ id: doc.id, ...doc.data() });
                }
            });
            console.log(newDataArray)
            setDataArray(newDataArray);
        });
        const timer = setTimeout(() => {
            setLoader(false)
        }, 2000);
        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>Agribolt</title>
                <meta
                    name="description"
                    content="Best-in-className AI technology combined with expertise in Agriculture & chemistry"
                />
            </Helmet>
            <Scrollto />
            {loader && <div className="loader"></div>}
            <Hero />
            <Vision />
            <Why />
            <Technology />
            <Whyus />
            {
                dataArray.length > 0 &&
                <Blogs />
            }
            <Contact />
        </>
    )
}

export default Home