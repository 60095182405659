import React from 'react'
import {useEffect} from 'react'
import './Technology.css'
import man from '../../img/man.png'

const Technology = () => {
    useEffect(() => {
        return () => {
        }
    }, [])



    return (
        <>
            <section id="tech" className="why-us section-bg">
                <div className="container">
                    <div className="bic-section-title">
                        <h2> Best-in-class AI technology combined with expertise in Agriculture & chemistry</h2>
                    </div>
                    <div className="about-lists">

                        <div className="row">
                            <div className="col-lg-4">
                                <div style={{ display: "flex" }}>
                                    <img src={man} style={{ width: "200px", display: "block", alignItems: "center", margin: "auto" }}
                                        alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <ul>
                                    <li><i className="bx bx-check-double"></i> Machine Learning</li>
                                    <li><i className="bx bx-check-double"></i>Deep Learning</li>
                                    <li><i className="bx bx-check-double"></i> Reinforcement Learning</li>
                                    <li><i className="bx bx-check-double"></i>Active Learning </li>
                                </ul>
                            </div>
                            <div className="col-lg-4">
                                <ul>
                                    <li><i className="bx bx-check-double"></i> Agronomy</li>
                                    <li><i className="bx bx-check-double"></i> Soil Science</li>
                                    <li><i className="bx bx-check-double"></i>AgriTech</li>
                                    <li><i className="bx bx-check-double"></i> Plant Genetics</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Technology