import React from 'react'
import './Whyus.css'
const Whyus = () => {
  return (
    <>
    <section id="sect-why-us" className="sect-why-us section-bg">
      <div className="container">
        <div className="section-title">
          <h2>How do we design novel agrochemicals?</h2>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-12 mt-6 d-flex align-items-stretch">
            <div className="card">
              <div className="card-icon">
                <i className="bx bx-brain"></i>
              </div>

              <div className="card-body">

                <p className="card-text">Understanding the
                  soil microbiota &
                  plant omics studies</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 d-flex align-items-stretch">
            <div className="card">
              <div className="card-icon">
                <i className="bx bx-test-tube"></i>
              </div>

              <div className="card-body">

                <p className="card-text">Identifying key
                  targets to improve
                  quality & protect
                  plants</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 d-flex align-items-stretch">
            <div className="card">
              <div className="card-icon">
                <i className="bx bx-edit-alt"></i>
              </div>
              <div className="card-body">


                <p className="card-text">Designing novel
                  agrochemicals that
                  nourish & protect
                  the crops</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="card">
              <div className="card-icon">
                <i className="bx bx-stats"></i>
              </div>
              <div className="card-body">

                <p className="card-text">Planning the
                  synthesis of the
                  designed novel
                  agro-chemicals</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Whyus