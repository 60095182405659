import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/vendor/boxicons/css/boxicons.min.css'
import "./assets/css/bootstrap.min.css"
import "./assets/css/animate.css"
import "./assets/css/lightcase.css"
import "./assets/css/simple-line-icons.css"
import "./assets/css/ElegantIcons.css"
import "./assets/css/swiper.min.css"
import "./assets/css/font-awesome.min.css"
import "./assets/css/style.css"
import "./assets/css/home-4-style.css"
import "./assets/css/responsive.css"
import 'bootstrap/dist/js/bootstrap.bundle'

import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
);
