import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Routes,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import BlogPosts from "./pages/BlogPosts/BlogPosts";
import Header from "./components/Header/Header";
import Footer from './components/Footer/Footer';
import Allblogs from "./pages/Allblogs/Allblogs";
import CategorieBlogs from "./pages/CategorieBlogs/CategorieBlogs";
import Scrollto from "./components/Scrollto/Scrollto";
import SearchResults from "./pages/SearchResults/SearchResults";
import BlogsRoot from './pages/BlogsRoot/BlogsRoot'
import { HelmetProvider } from "react-helmet-async";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

function App() {
  return (
    <>
      <HelmetProvider>
        <Scrollto />
        <Header />
        <main id="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/post/:id" element={<BlogPosts />} />
            <Route path="/blogs" element={<BlogsRoot />} >
              <Route path="" element={<Allblogs />} />
              <Route path=":search" element={<SearchResults />} />
            </Route>
            <Route path="/:categorie/blog" element={<CategorieBlogs />} />
            <Route path="/agribolt/:search/blogs" element={<SearchResults />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </main>
        <Footer />
      </HelmetProvider>
    </>
  );
}

export default App;
