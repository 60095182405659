import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { collection, db, onSnapshot } from '../../firebaseConfig';
import Scrollto from '../../components/Scrollto/Scrollto';
import Htmlrendering from '../../utils/Htmlrendering/Htmlrendering';
import Imgrendering from '../../utils/Imgrendering/Imgrendering';

const CategorieBlogs = () => {
  const [dataArray, setDataArray] = useState([]);
  const navigate = useNavigate()

  const breadstyle = {
    position: "relative",
    zIndex: "1",
    padding: "135px 0px 125px",
    overflow: "hidden",
    background: "green"
  }
  const { categorie } = useParams();

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'agrebolt-posts'), (querySnapshot) => {
      const newDataArray = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().published == true) {
          newDataArray.push({ id: doc.id, ...doc.data() });
        }
      });
      console.log(newDataArray)
      setDataArray(newDataArray);
    });
    return () => {
    }
  }, [])

  return (
    <>
      <Scrollto />
      <section style={breadstyle}>
        <img className="breadcrumb_shap" src="img/breadcrumb/banner_bg.png" alt="" />
        <div className="container">
          <div className="breadcrumb_content text-center">
            <div className='row'>
              <div className='col-lg-12 col-md-'>
                <h1 style={{ color: 'white' }}>categorie : {categorie}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="body_wrapper">
        <section className="blog_area sec_pad">
          <div className="container">
            <div className="row" id="blog_masonry">
              {
                dataArray.map((data, i) => {
                  return <>
                    {
                      data.categories[0] == categorie &&
                      <div id="Blog" className="col-lg-3 col-sm-6" onClick={() => navigate(`/post/${data.id}`)}>
                        <div className="blog_grid_item mb-30">
                          <div className="blog_content">
                            <div className="blog_img">
                              <Imgrendering
                                htmlString={data.content}
                              />
                            </div>
                            <NavLink to={`/post/${data.id}`}>
                              <h5 className="f_p f_size_20 f_500 t_color mb_20 len_text">{data.title}</h5>
                            </NavLink>
                            <div class="entry_post_info">
                              By: <NavLink to={`/post/${data.id}`}>{data.author}</NavLink>
                              <NavLink to={`/post/${data.id}`}>{data.created_date.toDate().toString().slice(4, 15)}</NavLink>
                            </div>
                            <div className='blog-description'>
                              <Htmlrendering content={data.content} />
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </>
                })
              }
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default CategorieBlogs