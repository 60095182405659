import React from 'react'

import hand from "../../img/hand.png";
import './Vision.css';

const Vision = () => {
    return (
        <>
            <section id="Vision" className="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6" style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ height: "250px", width: "250px", display: "block", margin: "auto" }} src={hand} alt="" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0">
                            <div className="row">
                                <div className="col-lg-12 pt-4 pt-lg-0 con1" style={{ height: "30vh" }}>
                                    <h3>Mission </h3>
                                    <p>
                                        To start the next generation of green revolution powered with AI. The green revolution was seen to
                                        increase the yield of food grain crops to eradicate world hunger. It’s time to increase the crop
                                        quality.
                                    </p>
                                </div>
                                <div className="col-lg-12 pt-4 pt-lg-0 con2" style={{ height: "30vh" }}>
                                    <h3>Vision </h3>
                                    <p>
                                        Better food. Better life
                                        Better food always brings out the best in people, be it happiness, energy, productivity, or
                                        creativity. We envision the world to be a lot better than today by improving the quality of crops
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Vision